// import Link from "next/link";

const NotFound = () => {
    
    return(
        <div className="container">
            <div className="page-not-found">
                <h1>क्षमा करें</h1>
                <p>आपके द्वारा अनुरोध किया गया पेज उपलब्‍ध नहीं है.</p>
                <p>होम पेज पर जाने के लिए यहां <a href="/" passHref><a>क्लिक करें</a></a></p>
            </div>
        </div>
    )
}

export default NotFound;