import { logger } from "logging/log-util";

function share(share) {
  if (navigator.share)
    navigator
      .share(share)
      .then(() => console.log("Successful share"))
      .catch((error) => logger.error("Error sharing", error));
}

export default { share };
