import {
  DELETE_BOOKMARKS_INIT,
  GET_BOOKMARKS_BY_TYPE_INIT,
  GET_BOOKMARKS_LIST_INIT,
  SAVE_BOOKMARKS_INIT,
} from "redux/actionTypes/bookmarks.actionTypes";

export const getBookmarksByType = (payload) => {
  return { type: GET_BOOKMARKS_BY_TYPE_INIT, payload };
};

export const getBookmarksList = () => {
  return { type: GET_BOOKMARKS_LIST_INIT };
};

export const saveBookmark = (payload) => {
  return { type: SAVE_BOOKMARKS_INIT, payload };
};

export const clearAllBookmarks = (payload) => {
  // console.log("clearAllBookmarks", payload)
  return { type: DELETE_BOOKMARKS_INIT, payload };
};