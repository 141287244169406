import { useRouter } from "next/router";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Wrapper = styled.div`
  .modal {
    height: 250px;
    width: 350px;
  }

  .modalfooter .btn {
    width: 10rem !important;
  }
`;
const CustomPopup = ({ showModal, onClose }) => {
  const router = useRouter();
  return (
    <Wrapper>
      <Modal contentClassName="modal login-next" show={showModal} centered dialogClassName="frist-login">
        <Modal.Body>
          <div className="RegiModalBody">
            <div>
              <h4>
                <FormattedMessage
                  id="this_feature"
                  defaultMessage="यह फीचर इस्तेमाल करने के लिए लॉगिन करें"
                />
              </h4>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalfooter">
            <div className="d-flex justify-content-between flex-direction-row">
              <Button
                variant="primary"
                type="button"
                onClick={() =>
                  router.push({
                    pathname: "/login",
                    query: { callbackUrl: router.asPath },
                  })
                }
              >
                {" "}
                <FormattedMessage id="signin" defaultMessage="साइन इन करें" />
              </Button>
              <Button variant="primary" type="button" onClick={onClose}>
                {" "}
                <FormattedMessage id="close" defaultMessage="बंद करें" />
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default CustomPopup;
