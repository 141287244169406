import {
  GET_TRENDING_POST_INIT,
  LIVESHOWPOST_GET_INIT,
  POST_LIKE_INIT,
  LIVE_STREAM_DATA_INIT,
  POST_VIEW_COUNT_INIT,
  GET_ALL_POST_INIT,
  GET_ALL_FEATURED_POST_INIT,
  VISUALSTORY_GET_INIT,
  GET_RELATED_ARTICLES_INIT,
  LIVEPOSTSOCKET_GET_INIT,
  GET_STATISTICS_INIT
} from "redux/actionTypes/post.actionTypes";

//live show post actions

export const getLiveShowPost = () => {
  return { type: LIVESHOWPOST_GET_INIT };
};
export const getLivePostBySocket = (payload) => {
  return {
    type: LIVEPOSTSOCKET_GET_INIT,
    payload
  }
};


// trending POSTS
export const getTrendingPosts = () => {
  return { type: GET_TRENDING_POST_INIT };
};

// LIKE-UNLIKE ACTIONS
export const postLike = (payload, postType, postDetails, recordPerPage) => {
  //console.log("postdetail",postDetails)
  return {
    type: POST_LIKE_INIT,
    payload,
    postType,
    postDetails,
    recordPerPage,
  };
};

//GET STATISTICS
export const getStats = (payload) => {
  return {
    type: GET_STATISTICS_INIT,
    payload
  };
}
// live streaming actions
export const getliveStreamData = (payload) => {
  return { type: LIVE_STREAM_DATA_INIT, payload };
};
// stories view Count actions
export const storyView = (payload, postType) => {
  return { type: POST_VIEW_COUNT_INIT, payload, postType };
};
// post by categoryType
export const allpostData = (payload) => {
  return { type: GET_ALL_POST_INIT, payload };
};
// post by featuredData
export const allFeaturedData = (payload) => {
  return { type: GET_ALL_FEATURED_POST_INIT, payload };
};

// get visual story by id
export const getVisualStoryDetailsById = (payload) => {
  //console.log("called");
  return { type: VISUALSTORY_GET_INIT, payload };
};

// Related ACTIONS
export const getRelatedNews = (payload) => {
  return { type: GET_RELATED_ARTICLES_INIT, payload };
};