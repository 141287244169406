export const timeSince = (date) => {
  let seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    const toReturn = Math.floor(interval);
    return `${toReturn} ${toReturn === 1 ? "year" : "years"}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const toReturn = Math.floor(interval);
    return `${toReturn} ${toReturn === 1 ? "month" : "months"}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const toReturn = Math.floor(interval);
    return `${toReturn} ${toReturn === 1 ? "day" : "days"}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const toReturn = Math.floor(interval);
    return `${toReturn} ${toReturn === 1 ? "hour" : "hours"}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    const toReturn = Math.floor(interval);
    return `${toReturn} ${toReturn === 1 ? "minute" : "minutes"}`;
  }
  const finalSeconds = Math.floor(seconds);
  return (finalSeconds >= 0 ? finalSeconds : 0) + " seconds";
};

export const msToTime = (duration) => {
  let milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  if (hours > 0) return hours + ":" + minutes + ":" + seconds;
  return minutes + ":" + seconds;
};

export const formatDate = (dateString) => {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };
  return {
    date: new Date(dateString).toLocaleDateString("hi-IN", dateOptions),
    time: new Date(dateString).toLocaleTimeString("en-US", timeOptions),
  };
};
export const formatDateEnglishOnly = (dateString) => {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };
  return {
    date: new Date(dateString).toLocaleDateString("en-US", dateOptions),
    time: new Date(dateString).toLocaleTimeString("en-US", timeOptions),
  };
};

export const formatDateIST = (dateString) => {
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return {
    date: new Date(dateString).toLocaleDateString("hi-IN", dateOptions),
    time: new Date(dateString).toLocaleString("en-US", timeOptions),
  };
};
export const formatDateISTCricket = (dateString) => {
  var date = new Date(dateString);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };
  const timeOptions = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return {
    date: new Date(date).toLocaleDateString("en-US", dateOptions),
    time: new Date(date).toLocaleString("en-US", timeOptions),
  };
};

export const formatDateDefault = (date) => {
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  if (hour > 12) {
    hour = hour - 12;
  }
  if (hour == 0) {
    hour = 12;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }
  let timeString = hour + ":" + minute + ":" + second + " ";
  return date.toISOString().substring(0, 10) + ` ${timeString}`;
};

export const getMonthName = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[date.getMonth()]?.slice(0, 3);
};
export const isoStringWithOffset = (date) => {
  date = new Date(date);
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

export const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export const myCustomSplit = (str) => {
  let outputArr = [];
  outputArr = str?.split("</p>");
  outputArr?.map((data, index) => {
    outputArr[index] = outputArr[index] + "</p>";
  });

  let totalo = 0; //Total opening blockquote tags
  let totalc = 0; // Total closing blockquote tags
  for (let x = 0; x < outputArr?.length; x++) {
    const opening = outputArr[x]?.split("<blockquote").length - 1;
    /*  Counts how many opening and closing blockquote tags
        are there on the current position of the array  */
    const closing = outputArr[x]?.split("</blockquote>").length - 1;
    totalo = opening;
    totalc = closing;

    if (closing !== opening) {
      let ind = x;
      /*Keep merging all the indexes ahead to outputArr[ind] 
           till the time we achieve closing===opening */
      while (true) {
        x++;
        outputArr[ind] = outputArr[ind] + outputArr[x];
        const openingInternal = outputArr[x]?.split("<blockquote").length - 1;
        const closingInternal = outputArr[x]?.split("</blockquote>").length - 1;
        outputArr[x] = "";
        totalo = totalo + openingInternal;
        totalc = totalc + closingInternal;

        if (totalo === totalc) {
          totalo = 0;
          totalc = 0;
          break;
        }
      }
    }
  }
  let finalArray = [];

  for (let x = 0; x < outputArr?.length; x++) {
    //Remove all indexes whose length have become 0
    if (outputArr[x]?.length !== 0) {
      finalArray.push(outputArr[x]);
    }
  }
  return finalArray;
};

/*Splits the html content by p tag, If we find a
       blockquote tag opening and  and it's respective closing tag in some other
       index below , we will merge all the indexes in between
       to a single index till the opening and closing tag of that blockquote doesnt
       come in 1 index */

// Current Ad logic-
// After 1st,4th
export const adsPositioning = (splitedArray) => {
  splitedArray.map((article, index) => {
    const opening = article.split("<p>").length - 1;
    const closing = article.split("</p>").length - 1;
    if (opening !== closing) {
      splitedArray[index] = splitedArray[index] + "</p>";
    }
  });

  let ar = [];
  ar[0] = splitedArray[0];
  ar[1] = splitedArray[1] !== undefined ? splitedArray[1] : "";
  ar[2] =
    (splitedArray[2] !== undefined ? splitedArray[2] : "") +
    (splitedArray[3] !== undefined ? splitedArray[3] : "");
  ar[3] = "";
  for (let x = 4; x < splitedArray.length; x++) {
    ar[3] = ar[3] + splitedArray[x];
  }
  return ar;
};

export const adsDiv = (adsId, indexonScreen, articleIndex) => {
  return (
    <div className="container-row py-2 ads-auto-resize" align="center">
      <p
        id="ads"
        style={{
          fontSize: "11px !important",
          color: "#b7b7b7",
          textAlign: "center",
          marginBottom: "0px",
        }}
      >
        Advertisement
      </p>
      {indexonScreen === articleIndex && (
        <div id={adsId}>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `{googletag.cmd.push(function() { googletag.display('${adsId}')})`,
            }}
          ></script>
        </div>
      )}
    </div>
  );
};
export const removeEmbeds = (articleHtmlString) => {
  var fs = "";
  var len = 7;
  var lenC = 9;
  for (var i = 0; i < articleHtmlString.length; i++) {
    const c = articleHtmlString.charAt(i);
    if (
      c === "<" &&
      i + len <= articleHtmlString.length &&
      articleHtmlString.substring(i, i + len) === "<script"
    ) {
      i = i + len;
      while (articleHtmlString.substring(i, i + lenC) !== "</script>") {
        i++;
      }
      i = i + lenC;
    } else {
      fs = fs + c;
    }
  }

  return fs;
};

export function divideArrayIntoColumns(arr, n) {
  if (n <= 0) {
    throw new Error("Number of columns (n) must be greater than 0.");
  }

  const result = [];
  const numRows = Math.ceil(arr.length / n);

  for (let i = 0; i < n; i++) {
    const column = [];
    for (let j = 0; j < numRows; j++) {
      const index = j * n + i;
      if (index < arr.length) {
        column.push(arr[index]);
      }
    }
    result.push(column);
  }

  return result;
}

export function removeHtmlTags(input) {
  // Use a regular expression to remove HTML tags
  return input?.replace(/<[^>]*>/g, "");
}
